<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" max-width="550" transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-icon small color="white" class="close-submit-dialog" @click="close">$close</v-icon>
      <v-list class="pa-0">
        <v-img class="p-abs img-header" src="@/assets/img/login-default-bg.png" min-width="250" width="100%" max-height="70px" />
        <div class="title-header">
          <v-list-item-title class="text-h5 font-weight-light white--text px-5 p-rel white--text pt-2">Catégorie</v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt mb-1 px-5 white--text p-rel">
            {{ item ? 'Modifier la catégorie' : 'Créer une nouvelle catégorie' }}
          </v-list-item-subtitle>
        </div>
        <div class="splitter" />
        <v-form ref="fieldset" class="pa-2" lazy-validation transition="scroll-y-transition" @submit.prevent="submit">
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <v-text-field v-model="fieldSet.title" :maxlength="80" hide-details="auto" outlined dense rounded label="Titre de la catégorie" :rules="[$rules.required]" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="fieldSet.subtitle" :maxlength="90" hide-details="auto" outlined dense rounded label="Sous-titre de la catégorie" :rules="[$rules.required]" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model.number="fieldSet.position" type="number" hide-details="auto" outlined dense rounded label="Position dans le formulaire"
                            :rules="[$rules.required, $rules.positive, $rules.between(1, 50)]"
              />
            </v-col>
          </v-row>
          <v-card-actions class="justify-end">
            <v-btn x-small rounded text @click="close">
              Annuler
            </v-btn>
            <v-btn x-small rounded color="success" type="submit" :loading="submitting" :disabled="submitting">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import {create} from '@/modules/forms/api/field_sets'
  import api from '@/services/api'

  export default {
    name: 'FieldSetForm',
    props: {
      form: String,
      showForm: Boolean,
      item: Object
    },
    data() {
      return{
        submitting: false,
        fieldSet :{
          form: this.form,
          title: this.item ? this.item.title : '',
          subtitle: this.item ? this.item.subtitle : '',
          position: this.item ? this.item.position : null
        }
      }
    },
    methods: {
      async submit() {
        if (!this.$refs.fieldset.validate()) {
          return false
        }
        try {
          this.submitting = true
          let response = this.item ? await api.put(this.item['@id'], this.fieldSet): await create(this.fieldSet)
          this.item ? this.$emit('update-field-set',  response.data) : this.$emit('add-field-set', response.data)
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: this.item ? 'Catégorie modifiée avec succès.' : 'Catégorie crée avec succès.'
          })
          this.close()
        } catch (error) {
          /* TODO: Traduction message erreur api */
          if(error.response.status === 422) {
            this.$root.$emit('snackbar:open', {
              snackbar: ()=> import('@/modules/core/components/Snackbar'),
              type: 'error',
              title: 'Cette position est déja utilisée dans votre formulaire'
            })
          }
          else {
            this.$root.$emit('snackbar:open', {
              snackbar: ()=> import('@/modules/core/components/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue'
            })
          }

        } finally {
          this.submitting = false
        }
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>
