<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card v-if="form" class="elevation-x">
    <v-list-item>
      <v-list-item-avatar class="mr-0 success c-pointer" size="30" @click="showEditForm = !showEditForm">
        <font-awesome-icon :icon="['fad','pencil']" size="1x" class="white--text ma-auto" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-card-title class="font-alt pb-2">{{ form.label }}</v-card-title>
        <v-card-subtitle class="o-60">
          Formulaire de l'établissement
          <span class="secondary--text font-weight-bold">{{ activeEstablishment.label }}</span>
        </v-card-subtitle>
      </v-list-item-content>
      <v-list-item-action class="d-flex flex-row align-center">
        <v-btn v-if="!form.alreadyBeenActivated" x-small rounded color="transparent grey--text text--darken-2" class="font-alt btn-add mr-2" elevation="0"
               dark @click="showFieldSetForm = true"
        >
          <font-awesome-icon :icon="['fad','plus-octagon']" size="sm" class="mr-2" />
          Ajouter une catégorie
        </v-btn>

        <v-btn v-if="form.fieldSets.length" x-small rounded color="transparent grey--text text--darken-2"
               class="font-alt btn-add mr-2"
               elevation="0" dark @click="showFieldForm = true"
        >
          <font-awesome-icon :icon="['fad','plus-octagon']" size="sm" class="mr-2" />
          Ajouter une question
        </v-btn>
      </v-list-item-action>
      <v-btn x-small rounded color="success" class="font-alt btn-add mr-2"
             elevation="0" dark :loading="activating" :disabled="activating" @click="modalActivation"
      >
        <font-awesome-icon :icon="['fad','check-double']" size="sm" class="mr-2" />
        {{ model.active ? 'Formulaire actif' : 'Activer ce formulaire' }}
      </v-btn>
    </v-list-item>

    <v-list v-if="!loading">
      <div v-for="fieldset in sortedFieldsets(form.fieldSets)" :key="fieldset.id" class="py-3">
        <v-list-item>
          <v-list-item-avatar class="mr-0 success c-pointer" size="25" @click="select(fieldset)">
            <font-awesome-icon :icon="['fad','pencil']" size="xs" class="white--text ma-auto" />
          </v-list-item-avatar>
          <v-list-item-content class="pa-0">
            <v-card-title class="font-alt pb-2">{{ fieldset.title }}</v-card-title>
            <v-card-subtitle class="o-60">
              {{ fieldset.subtitle }}
            </v-card-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-row>
            <v-col v-for="field in fields(fieldset)" :key="field.id" cols="12" md="6" class="p-rel field-form">
              <v-btn x-small class="form-field-edit-btn" @click.prevent="selectField(field, fieldset)">
                <font-awesome-icon :icon="['fad','pencil']" size="sm" class="success--text" />
              </v-btn>
              <field :field="field" class="w-100" />
            </v-col>
          </v-row>
        </v-list-item>
      </div>
      <field-set-form v-if="showFieldSetForm" :form="form['@id']" :show-form="showFieldSetForm" :item="fieldSetItem"
                      @close="close" @add-field-set="addFieldSet" @update-field-set="updateFieldSet"
      />
      <field-form v-if="showFieldForm" :show-form="showFieldForm"
                  :field-sets="sortedFieldsets(form.fieldSets)" :field-set-selected="fieldSetSelected"
                  :field.sync="fieldItem" @close="close" @add-field="addField" @update-field="updateField"
      />
      <form-create v-if="showEditForm" :form="form" @close="close" @update-form="updateForm" />
    </v-list>
    <modal v-model="activate" title="Activer le formulaire" color="color-dialog informations"
           subtitle="Une fois le formulaire activé, aucune modification ne pourra être effectuée. Êtes vous certain de vouloir valider ce formulaire?"
           :img="require('@/assets/img/illustrations/new-entry.svg')" :actions="true"
    >
      <template v-slot:actions>
        <v-btn color="primary" small text @click="activateForm">
          Oui j'ai compris
        </v-btn>
      </template>
    </modal>
  </v-card>
</template>

<script>
  import Modal from '@/modules/core/components/Modal'
  import FormCreate from '@/modules/forms/components/FormDialog'
  import FieldForm from '@/modules/forms/components/FieldForm'
  import FieldSetForm from '@/modules/forms/components/FieldSetForm'
  import Field from '@/modules/forms/components/Field'
  import {get, update} from '@/modules/forms/api/forms'
  import {mapGetters} from 'vuex'

  export default {
    name: 'FormUpdate',
    components: {FieldSetForm, Field, FieldForm, FormCreate, Modal},
    props: {
      id: String
    },
    data() {
      return {
        model: {
          label: '',
          fieldSets: []
        },
        activate: false,
        showFieldSetForm: false,
        showFieldForm: false,
        showEditForm: false,
        submitting: false,
        activating: false,
        form: null,
        fieldSetItem: null,
        fieldItem: null,
        fieldSetSelected: null,
        loading: true
      }
    },
    computed: {
      ...mapGetters('establishments', ['activeEstablishment']),
      sortedFieldsets(){
        return fieldSets => fieldSets.slice().sort(function(a,b){
          return a.position - b.position
        })
      },
      fields() {
        return fieldSet => fieldSet.fields.filter(field => field.type).sort(function(a,b){
          return a.position - b.position
        })
      }
    },
    watch: {
      '$store.state.establishments.activeEstablishmentId'() {
        this.$router.push({name: 'forms-list'})
      }
    },
    async created() {
      await this.loadForm()
      this.model = {
        active: this.form['@id'] === this.activeEstablishment.activeForm,
        label: this.form.label,
        fieldSets: this.form.fieldSets
      }
      this.loading = false
    },
    methods: {
      modalActivation(){
        this.activate = true
      },
      async submit() {
        try {
          this.submitting = true
          await update(this.form.id, this.model)
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.submitting = false
          this.activate = false
        }
      },
      async loadForm() {
        try {
          this.form = (await get(this.id, {cache: { ignoreCache: true }})).data
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        }
      },
      async activateForm() {
        try {
          this.activating = true
          await this.$store.dispatch('establishments/activateForm', this.form['@id'])
          await this.$router.push({name: 'forms-list'})
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.activating = false
        }
      },
      select(fieldset) {
        this.showFieldSetForm = !this.showFieldSetForm
        this.fieldSetItem = fieldset
      },
      selectField(field, fieldset) {
        this.showFieldForm = !this.showFieldForm
        this.fieldItem = field
        this.fieldSetSelected = fieldset['@id']
      },
      close() {
        this.showFieldSetForm = false
        this.showFieldForm = false
        this.showEditForm = false
        this.fieldItem = null
        this.fieldSetItem = null
      },
      findFieldSet(id) {
        return this.form.fieldSets.find(fieldSet => fieldSet['@id'] === id)
      },
      findField(fieldSetId, fieldId) {
        return this.findFieldSet(fieldSetId).fields.find(field => field['@id'] === fieldId)
      },
      addField(data) {
        if (data) {
          this.findFieldSet(data.fieldSet).fields.push(data)
        }
      },
      updateField(data) {
        if (data) {
          Object.assign(this.findField(data.fieldSet, data['@id']), data)
        }
      },
      addFieldSet(data) {
        if (data) {
          this.form.fieldSets.push(data)
        }
      },
      updateFieldSet(data) {
        if (data) {
          Object.assign(this.form.fieldSets[this.form.fieldSets.indexOf(this.fieldSetItem)], data)
        }
      },
      updateForm(data) {
        if (data) {
          Object.assign(this.form, data)
        }
      }
    }
  }
</script>
