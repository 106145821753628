<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" max-width="550" transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-icon small color="white" class="close-submit-dialog" @click="close">$close</v-icon>
      <v-list class="pa-0">
        <v-img class="p-abs img-header" src="@/assets/img/login-default-bg.png" min-width="250" width="100%" max-height="70px" />
        <div class="title-header">
          <v-list-item-title class="text-h5 font-weight-light white--text px-5 p-rel white--text pt-2">Question</v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt mb-1 px-5 white--text p-rel">
            {{ field ? 'Modifier une question' : 'Créer une nouvelle question' }}
          </v-list-item-subtitle>
        </div>
        <div class="splitter" />
        <v-form ref="field" class="pa-2" lazy-validation transition="scroll-y-transition" @submit.prevent="submit">
          <v-row class="ma-0">
            <v-col cols="12" md="12">
              <v-select v-model="fieldModel.fieldSet" :items="fieldSets" item-text="title" item-value="@id" hide-details="auto"
                        outlined dense label="Choisir une catégorie" :rules="[$rules.required]" rounded
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field v-model="fieldModel.label" :maxlength="230" hide-details="auto" rounded outlined dense label="Libellé de la question" :rules="[$rules.required]" />
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field v-model.number="fieldModel.position" :maxlength="2" type="number" rounded hide-details="auto" outlined dense min="1"
                            label="Position dans la catégorie"
                            :rules="[$rules.required, $rules.between(1, 50)]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select v-model="fieldModel.type" :maxlength="100" hide-details="auto" rounded outlined dense label="Type de question" :items="options" :rules="[$rules.required]" />
            </v-col>
            <v-col cols="12" md="6">
              <v-select v-model="fieldModel.required" rounded hide-details="auto" :items="choice" outlined dense label="Champ requis" :rules="[$rules.required]" />
            </v-col>
            <template v-if="field && fieldModel.type === 'choice'">
              <v-card-title class="font-alt primary--text text-subtitle-2 pb-0">Ajouter des choix de réponses à ma question :</v-card-title>
              <v-col cols="12">
                <v-text-field v-model="newChoiceModel.label" hide-details="auto" rounded outlined dense label="Ajouter une valeur" :maxlength="100">
                  <template v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small elevation="0" height="40" v-bind="attrs" :loading="loadingNewValue" :disabled="loadingNewValue" @click="submitNewValue" v-on="on">
                          <font-awesome-icon :icon="['fad','check-double']" size="sm" class="success--text" />
                        </v-btn>
                      </template>
                      <span>Ajouter ma valeur</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col v-for="item in choicesModel" :key="item['@id']" cols="12">
                <v-text-field v-model="item.label" hide-details="auto" outlined rounded dense label="Valeur" :maxlength="100">
                  <template v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" x-small elevation="0" height="40" :loading="loading"
                               :disabled="loading" @click="submitChoice(item)" v-on="on"
                        >
                          <font-awesome-icon :icon="['fad','check-double']" size="sm" class="success--text" />
                        </v-btn>
                      </template>
                      <span>Modifier ma valeur</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </template>
          </v-row>
          <v-card-actions class="justify-end">
            <v-btn x-small rounded text @click="close">
              Annuler
            </v-btn>
            <v-btn x-small rounded color="success" type="submit" :loading="submitting" :disabled="submitting">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import {create} from '@/modules/forms/api/fields'
  import * as choices from '@/modules/forms/api/choices'
  import api from '@/services/api'
  import {pick} from '@/utils/object'
  import {replaceItem} from '@/utils/array'

  export default {
    name: 'FieldForm',
    props: {
      fieldSets: Array,
      field: Object,
      fieldSetSelected: String
    },
    data() {
      return{
        loading: false,
        loadingNewValue: false,
        submitting: false,
        fieldModel: {
          label: this.field ? this.field.label : '',
          type: this.field ? this.field.type : '',
          position: this.field ? this.field.position : null,
          required:this.field ? this.field.required : false,
          fieldSet: this.field ? this.fieldSetSelected : ''
        },
        choicesModel: this.field ? this.field.choices.map(choice => {
          return pick(choice, ['@id', 'label'])
        }) : '',
        newChoiceModel: {
          label: ''
        },
        options: [
          {text: 'Choix Oui/Non', value: 'boolean'},
          {text: 'Choix', value: 'choice'},
          {text: 'Texte', value: 'string'},
          {text: 'Date', value: 'date'},
          {text: 'Document', value: 'document'}
        ],
        choice: [
          {text: 'Non', value: false},
          {text: 'Oui', value: true}
        ]
      }
    },
    computed: {
      fieldChoice() {
        return id => this.field.choices.find(choice => choice['@id'] === id)
      },
      localField: {
        get(){
          return this.field
        },
        set(value) {
          this.$emit('update:value', value)
        }
      }
    },
    methods: {
      async submit() {
        if (!this.$refs.field.validate()) {
          return false
        }
        try {
          this.submitting = true
          let response = this.field ? await api.put(this.field['@id'], this.fieldModel) : await create(this.fieldModel)
          this.field ? this.$emit('update-field',  response.data) : this.$emit('add-field', response.data)
          this.close()
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: this.field ? 'Question modifiée avec succès.' : 'Question crée avec succès.'
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.submitting = false
        }
      },
      async submitNewValue() {
        try {
          this.loadingNewValue = true
          let response = await choices.create({field: this.field['@id'], label: this.newChoiceModel.label})
          this.choicesModel.push(pick(response.data, ['@id', 'label']))
          this.newChoiceModel.label = ''
          this.localField.choices.push(response.data)
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: 'Valeur ajoutée avec succès.'
          })
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.loadingNewValue = false
        }
      },
      async submitChoice(choice) {
        try {
          this.loading = true
          let newChoice = (await api.put(choice['@id'], {label: choice.label})).data
          replaceItem(this.field.choices,  this.fieldChoice(choice['@id']), newChoice)
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: 'Valeur modifiée avec succès.'
          })
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.loading = false
        }
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>
