<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div>
    <v-text-field v-if="field.type === 'string'" outlined dense hide-details="auto"
                  :label="field.label" :rules="field.rules"
    >
      <template v-if="field.required" v-slot:append>
        <v-icon small color="red lighten-3" class="pt-2">$warning</v-icon>
      </template>
    </v-text-field>
    <v-select v-else-if="field.type === 'boolean'"
              :label="field.label" :items="options" :rules="field.rules" :multiple="field.multiple"
              color="secondary" clearable outlined dense hide-details="auto"
    >
      <template v-if="field.required" v-slot:append>
        <v-icon small color="red lighten-3" class="pt-2">$warning</v-icon>
      </template>
    </v-select>
    <v-select v-else-if="field.type === 'choice'"
              :label="field.label" :items="options" :rules="field.rules" :multiple="field.multiple"
              color="secondary" clearable outlined dense hide-details="auto"
    />
    <date-picker v-else-if="field.type === 'date'"
                 :label="field.label" :rules="field.rules" :date-picker-props="{max:maxDate}"
    />
    <v-file-input v-else-if="field.type === 'document'" hide-details="auto" outlined dense small-chips truncate-length="15"
                  prepend-icon="" color="secondary" :label="field.label"
    >
      <template v-if="field.required" v-slot:append>
        <v-icon small color="red lighten-3" class="pt-2">$warning</v-icon>
      </template>
    </v-file-input>
  </div>
</template>

<script>
  import DatePicker from '@/modules/core/components/DatePicker'
  import {column} from '@/utils/array'
  export default {
    name: 'Field',
    components: {DatePicker},
    props: {
      field: {
        type: Object,
        required: true
      }
    },
    computed: {
      options(){
        let options = []
        if(this.field.type === 'choice') {
          options= column(this.field.choices, 'label')
        } else if(this.field.type === 'boolean') {
          options = ['Oui', 'Non']
        }
        return options
      },
      maxDate() {
        return this.$dayjs().format('YYYY-MM-DD')
      }
    }
  }
</script>
